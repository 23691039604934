import React, { ReactElement } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import styled, { css } from 'styled-components'

import { variables } from 'src/Styled/variables'
import Loader from 'src/Assets/Svg/loader.svg?react'

export interface PrimaryButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean
}

export const StyledButton = styled.button<{
  loading?: boolean
}>`
  position: relative;
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  gap: 4px;

  background: ${({ theme, loading }) =>
    loading ? theme.button.secondaryBg : theme.button.primaryBg};

  border: ${({ theme }) => `1px solid ${theme.button.primaryBorder}`};
  color: ${({ theme }) => theme.button.primaryText};

  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  cursor: pointer;

  transition: all 0.2s;

  svg {
    path {
      fill: ${({ theme }) => theme.button.primaryText};
    }
  }

  &:hover {
    box-shadow: 0 0 12px 0 rgba(51, 51, 51, 0.12);
  }

  &:focus-visible {
    outline: ${({ theme }) => `1px solid ${theme.button.primaryText}`};
    outline-offset: -1px;
  }

  &:active {
    outline: none;
    background: ${({ theme }) => theme.button.secondaryBg};
    color: ${({ theme }) => theme.button.primaryText};
    svg {
      path {
        fill: ${({ theme }) => theme.button.primaryText};
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  ${({ loading }) => {
    if (!loading) {
      return css`
        &:disabled {
          color: ${({ theme }) => theme.button.secondaryText};
          svg {
            path {
              fill: ${({ theme }) => theme.button.secondaryText};
            }
          }
          cursor: not-allowed;
          box-shadow: none;
          background: ${({ theme }) => theme.button.primaryBg};
        }
      `
    }

    return css`
      &:hover {
        box-shadow: none;
      }
    `
  }}
`

const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 17px;
    height: 17px;
  }
`

const ChildrenWrapper = styled.div<{ loading?: boolean }>`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const PrimaryButton: React.FC<PrimaryButtonProps> = (
  props,
): ReactElement => {
  const { children, loading, onClick, disabled } = props

  const debouncedOnClick = useDebouncedCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    0,
    { trailing: true },
  )

  return (
    <StyledButton
      {...props}
      onClick={(e) => debouncedOnClick(e)}
      disabled={disabled || loading}
    >
      {loading && !disabled && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}

      <ChildrenWrapper loading={loading}>{children}</ChildrenWrapper>
    </StyledButton>
  )
}
