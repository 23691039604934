import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

export interface NewReleaseResponse {
  id: number
  createdAt: string
  albumGroup: null
  albumName: string
  albumSpotifyId: string
  albumType: string
  artistName: string
  artistSpotifyId: string
  emailSentAt: string
  releaseDate: string
  totalTracks: number
  trackDuration: number
  trackImageUrl: string
  trackName: string
  trackSpotifyId: string
  userId: number
  isRead?: boolean
}

const newReleaseApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewRelease: builder.query<NewReleaseResponse, void>({
      query: () =>
        `${APP.TIKTOK_SERVER}/artist/new-release-tracks/last-available`,
      transformResponse: (response: NewReleaseResponse) => ({
        ...response,
        isRead: false,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetNewReleaseQuery } = newReleaseApi
