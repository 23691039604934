import { FC, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAwaitingTracksSelector } from 'src/Redux/feed-process/selectors'
import {
  OnFullTrackListenedBody,
  SpotifyPreviewTrackPlayer,
} from 'src/Components/SpotifyPreviewTrackPlayer'
import { curatorFeedTrackListenedEvent } from 'src/Helpers/TagManager/CuratorsEvents'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

import { TrackListItem } from '../../TrackListItem'
import { ReviewMobileProps, SkipMobileProps } from '../props'
import { ListLimitMessage } from '../styles'
import { EmptyTrackList } from '../../EmptyTrackList'

interface Props {
  openReviewModal: (props: ReviewMobileProps) => void
  openSkipModal: (props: SkipMobileProps) => void
}
const getTrackId = (url: string): string => {
  if (!url) {
    return ''
  }
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
`
export const AwaitingCampaignsList: FC<Props> = (props) => {
  const awaitingCampaigns = useSelector(getAwaitingTracksSelector)
  const userId = useAppSelector(getUserId)
  const { openReviewModal, openSkipModal } = props
  const { t } = useTranslation()

  const handleFullTrackListened = useCallback(
    (data: OnFullTrackListenedBody) => {
      curatorFeedTrackListenedEvent({
        userId,
        position: data?.position,
        duration: data?.duration,
        trackId: data?.currentTrackUrl,
        campaignId: awaitingCampaigns.find(
          (el) => getTrackId(el.trackUrl) === data?.currentTrackUrl,
        )?.id,
      })
    },
    [awaitingCampaigns, userId],
  )
  if (awaitingCampaigns.length) {
    return (
      <SpotifyPreviewTrackPlayer
        onFullTrackListened={handleFullTrackListened}
        render={({
          isPlaying,
          loading,
          onClick,
          playbackData,
          currentTrackUrl,
        }) => (
          <Wrapper>
            {awaitingCampaigns.map((el) => {
              if (el.id) {
                return (
                  <TrackListItem
                    key={el.id}
                    trackName={el.trackName}
                    trackImage={el.trackImage}
                    artistName={el.artistName}
                    matchedGenres={el.matchedGenres}
                    matchedPlaylists={el.matchedPlaylists}
                    loading={
                      loading && currentTrackUrl === getTrackId(el.trackUrl)
                    }
                    isPlaying={
                      isPlaying && currentTrackUrl === getTrackId(el.trackUrl)
                    }
                    onClickPlayer={(e) => {
                      onClick(e, getTrackId(el.trackUrl))
                      if (isPlaying) {
                        curatorFeedTrackListenedEvent({
                          userId,
                          position: playbackData?.position,
                          duration: playbackData?.duration,
                          trackId: currentTrackUrl,
                          campaignId: el.id,
                        })
                      }
                    }}
                    openReviewModal={() =>
                      openReviewModal({ campaign_id: el.id })
                    }
                    openSkipModal={() => openSkipModal({ campaign_id: el.id })}
                  />
                )
              }
              return null
            })}
            <ListLimitMessage>
              {t('reviewsPage.yourListOfTracks')}
            </ListLimitMessage>
          </Wrapper>
        )}
      />
    )
  }
  return <EmptyTrackList>{t('reviewsPage.emptyAwaitingList')}</EmptyTrackList>
}
