import { FunctionComponent, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import Button from 'src/Components/Buttons/Button'
import { MIN_PAYOUT_AMOUNT } from 'src/Constants/constants'
import {
  getHasAwaiting,
  getTransactionsLoading,
} from 'src/Redux/transactions-process/selectors'
import {
  getInfluencerId,
  getIsBlockedCreatorSelector,
  getUser,
} from 'src/Redux/auth-process/userSlice/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getRecipient } from 'src/Redux/wise-process/selectors'
import { creatorPayoutRequested } from 'src/Helpers/TagManager'
import { HUNDREDTHS } from 'src/Constants/numeric'
import {
  getCreatorTransactions,
  getTransactionsDetails,
} from 'src/Redux/transactions-process/api-actions'
import { getUserInfo } from 'src/Redux/auth-process/userSlice/api-actions'
import { useCreatorOrderPayoutMutation } from 'src/Redux/orderPayoutApi'

import {
  ButtonsWrapper,
  RequestButtonWrapper,
  MinRequestText,
  Loader,
  MobileRequestPayout,
} from './styles'
export const InfluencerBalanceRequestButton: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userId = useSelector(getInfluencerId)
  const user = useSelector(getUser)
  const recipient = useSelector(getRecipient)
  const hasAwaiting = useSelector(getHasAwaiting)
  const isBlocked = useAppSelector(getIsBlockedCreatorSelector)
  const isBalanceHistoryLoading = useAppSelector(getTransactionsLoading)
  const [orderPayout] = useCreatorOrderPayoutMutation({
    fixedCacheKey: 'CreatorOrderPayout',
  })

  const onRequestPayout = (): void => {
    creatorPayoutRequested({
      userID: userId,
    })

    if (isRequestPayoutButtonEnabled) {
      orderPayout()
        .unwrap()
        .then((response) => {
          void dispatch(getCreatorTransactions(1))
          void dispatch(getTransactionsDetails())
          void dispatch(getUserInfo())
          return response
        })
    }
  }

  const buttonText = hasAwaiting
    ? t('balancePage.awaitingPayout')
    : t('balancePage.requestPayout')

  const isRequestPayoutButtonEnabled = useMemo(
    () =>
      Boolean(recipient && user.balance && user.balance >= MIN_PAYOUT_AMOUNT),
    [user, recipient],
  )

  const isDisabled = !isRequestPayoutButtonEnabled || hasAwaiting || isBlocked

  const isMobile = useMediaQuery({ maxWidth: 576 })

  const buttonWrapper = (
    <ButtonsWrapper>
      <RequestButtonWrapper>
        <Button type='green' disabled={isDisabled} onClick={onRequestPayout}>
          {isBalanceHistoryLoading ? (
            <Loader height={24} type='bars' />
          ) : (
            buttonText
          )}
        </Button>
      </RequestButtonWrapper>

      <MinRequestText>{`Min request: $${MIN_PAYOUT_AMOUNT.toFixed(
        HUNDREDTHS,
      )}`}</MinRequestText>
    </ButtonsWrapper>
  )

  if (isMobile) {
    return <MobileRequestPayout>{buttonWrapper}</MobileRequestPayout>
  }

  return buttonWrapper
}
