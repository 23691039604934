import { FunctionComponent } from 'react'

import { Route, Routes } from 'react-router-dom'

import { CampaignsListPage } from 'src/Pages/Artist/CampaignsListPage'

export enum ArtistRoutesEnum {
  Root = '/',
  CampaignsList = '/campaigns',
  New = '/new',
  Settings = '/settings',
  ReferAndEarn = '/refer-and-earn',
  Balance = '/balance',
  Subscription = '/subscription',
  Admin = '/admin',
}

export const ArtistComponentsNewUI: FunctionComponent = () => (
  <Routes>
    {/* <Route*/}
    {/*  Component={CampaignResults}*/}
    {/*  path={`${campaignsList}${campaignResults}`}*/}
    {/* >*/}
    {/*  <Route path=':campaignID' Component={CampaignResults} />*/}
    {/* </Route>*/}

    {/* <Route path={`${campaignsList}${details}`}>*/}
    {/*  <Route path=':campaignID' Component={TikTokCampaignDetails} />*/}
    {/*  <Route path=':campaignID/:videoID' Component={TiktokReview} />*/}
    {/* </Route>*/}

    {/* <Route Component={ReferAndEarn} path={referAndEarn} />*/}

    {/* <Route Component={ArtistBalancePage} path={balance} />*/}

    {/* <Route Component={Subscriptions} path={subscription}></Route>*/}
    {/* <Route path={`${subscription}/pay`} Component={PaySubscription} />*/}
    {/* <Route*/}
    {/*  path={`${subscription}/pay/success`}*/}
    {/*  Component={SuccessPaymentSubscription}*/}
    {/* />*/}
    {/* <Route path={`${subscription}/cancel`} Component={CancelSubscription} />*/}

    {/* <Route*/}
    {/*  Component={ScheduleSpotifyWrapper}*/}
    {/*  path={`${campaignsList}${scheduleCampaign}`}*/}
    {/* >*/}
    {/*  <Route path=':number' Component={ScheduleSpotifyWrapper} />*/}
    {/* </Route>*/}

    {/* <Route*/}
    {/*  Component={ScheduleTiktokWrapper}*/}
    {/*  path={`${campaignsList}${scheduleCampaignTikTok}`}*/}
    {/* >*/}
    {/*  <Route path=':number' Component={ScheduleTiktokWrapper} />*/}
    {/* </Route>*/}

    {/* <Route Component={Settings} path={settings}>*/}
    {/*  <Route path=':page' Component={Settings} />*/}
    {/* </Route>*/}

    {/* <Route*/}
    {/*  Component={CreateCampaignPage}*/}
    {/*  path={`${campaignsList}${createCampaign}`}*/}
    {/* />*/}
    {/* <Route*/}
    {/*  path={`${campaignsList}${createCampaign}/:platform`}*/}
    {/*  Component={SearchNewCampaignByPlatform}*/}
    {/* />*/}
    {/* <Route*/}
    {/*  path={`${createdFirst}/:campaignType`}*/}
    {/*  Component={SuccessCreatedFirstCampaign}*/}
    {/* />*/}
    {/* <Route*/}
    {/*  path={`${createdFirst}/:campaignType${feedback}`}*/}
    {/*  Component={FirstFeedback}*/}
    {/* />*/}

    {/* <Route Component={ExpiredLinkPage} path={errors} />*/}

    {/* {APP.admin_email.includes(String(email)) && (*/}
    {/*  <Route Component={AdminPage} path={admin} />*/}
    {/* )}*/}

    <Route Component={CampaignsListPage} path='*' />
    <Route Component={CampaignsListPage} path={ArtistRoutesEnum.Root} />
    <Route
      Component={CampaignsListPage}
      path={ArtistRoutesEnum.CampaignsList}
    />
  </Routes>
)
