import { FC, PropsWithChildren } from 'react'

import { PageLayout } from 'src/Components/PageLayout'

import { HeaderContainer } from './Components/HeaderContainer'
import { SidebarContainer } from './Components/SidebarContainer'

export const PageLayoutContainer: FC<PropsWithChildren> = ({ children }) => (
  <PageLayout Header={HeaderContainer} Sidebar={SidebarContainer}>
    {children}
  </PageLayout>
)
