import { useIntercom } from 'react-use-intercom'
import { useNavigate } from 'react-router-dom'

import { logOutEvent } from 'src/Helpers/TagManager'
import { logOut } from 'src/Redux/auth-process/credentialsSlice'
import { clearUserState } from 'src/Redux/auth-process/userSlice'
import { removeCookiesHandler } from 'src/Constants/functions'
import { rtkApi } from 'src/Services/rtkApi'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'

interface UsePageLayoutInterface {
  onLogout: () => void
}

export const usePageLayout = (): UsePageLayoutInterface => {
  const { shutdown } = useIntercom()
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onLogout = (): void => {
    shutdown()
    user && logOutEvent(user)
    dispatch(logOut())
    dispatch(clearUserState())
    navigate('/')
    removeCookiesHandler()
    dispatch(rtkApi.util.resetApiState())
    localStorage.removeItem('user')
  }

  return {
    onLogout,
  }
}
