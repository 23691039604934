import { FC } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 40px;
`

const Content = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  width: 100%;
  height: 200px;
`
export const CampaignsListPage: FC = () => (
  <Container>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
    <Content>Content</Content>
  </Container>
)
