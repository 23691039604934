import {
  FC,
  HTMLAttributeAnchorTarget,
  PropsWithChildren,
  SVGProps,
} from 'react'

import { Popover, PopoverProps } from 'antd'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

interface WrapperProps {
  $isFeatured: boolean
  $collapsed: boolean
  $disabled?: boolean
}

const Wrapper = styled(NavLink)<WrapperProps>`
  background-color: #000;
  color: ${({ $isFeatured, theme }) =>
    $isFeatured ? theme.common.primaryDetails : theme.common.secondaryText};
  display: flex;
  align-items: center;
  height: 40px;
  padding: ${({ $collapsed }) => ($collapsed ? '0px 20px' : '0px 16px;')};
  position: relative;
  font-family: 'Segoe UI';
  text-decoration: none;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ $isFeatured, theme }) =>
      $isFeatured ? theme.common.primaryDetails : theme.common.secondaryText};
  }
  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.common.thirdBg};

    color: ${({ $isFeatured, theme }) =>
      $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
    svg {
      fill: ${({ $isFeatured, theme }) =>
        $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
      transition: all 0.3s ease-in-out;
    }
  }
  &:focus-visible {
    outline: ${({ theme }) => theme.common.secondaryBorder};
    outline-offset: -1px;
    background-color: ${({ theme }) => theme.common.secondaryBg};
  }

  &.active {
    outline: none;
    color: ${({ theme }) => theme.common.fourthText};
    svg {
      fill: ${({ theme }) => theme.common.fourthText};
    }
  }
  transition: all 0.3s ease-in-out;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.common.primaryText : ''};

  svg {
    fill: ${({ $disabled, theme }) =>
      $disabled ? theme.common.primaryText : ''};
  }
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`
const Content = styled.div<{ $collapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ $collapsed }) => ($collapsed ? '0px' : '16px')};
`

const Text = styled.div<{ $collapsed?: boolean }>`
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
  width: ${({ $collapsed }) => ($collapsed ? '0px' : '160px')};
  overflow: hidden;
  opacity: ${({ $collapsed }) => ($collapsed ? '0' : '1')};
  transition: width 1.5s linear;
  transition: opacity 1s ease-in-out;
`

const StyledPopover = styled((props: PopoverProps) => (
  <Popover {...props} overlayClassName={props.className} />
))`
  .ant-popover-inner {
    padding: 9.5px 16px;
  }
`

const Tooltip = styled.div<{ $isFeatured?: boolean }>`
  color: ${({ $isFeatured, theme }) =>
    $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
`

export interface SidebarMenuItemProps extends PropsWithChildren {
  icon: FC<SVGProps<SVGSVGElement>>
  path: string
  collapsed: boolean
  active?: boolean
  target?: HTMLAttributeAnchorTarget
  isFeatured?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const SidebarMenuItem: FC<SidebarMenuItemProps> = (props) => {
  const {
    icon: Icon,
    children,
    path,
    onClick,
    collapsed,
    active,
    disabled,
    isFeatured,
    target = '_self',
  } = props
  return (
    <StyledPopover
      color='#333'
      arrow={false}
      placement='right'
      content={
        <Tooltip $isFeatured={Boolean(isFeatured) && !active}>
          {children}
        </Tooltip>
      }
      trigger={collapsed && !disabled ? ['hover', 'focus'] : 'contextMenu'}
    >
      <Wrapper
        target={target}
        $collapsed={Boolean(collapsed)}
        $isFeatured={Boolean(isFeatured)}
        $disabled={disabled}
        to={path}
        className={active ? 'active' : ''}
        onClick={onClick}
      >
        <Content $collapsed={collapsed}>
          <Icon />
          <Text $collapsed={collapsed}>{children}</Text>
        </Content>
      </Wrapper>
    </StyledPopover>
  )
}
