import { useCallback } from 'react'

import { useSelector } from 'react-redux'

import { getSpotifyTrack } from 'src/Redux/track-process/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { useCreateNewCampaign } from 'src/Containers/SearchNewCampaignByPlatform/SelectedNewCampaign/useCreateNewCampaign'
import { newReleasedPromoteEvent } from 'src/Helpers/TagManager/CampaignsEvents'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'

interface CreateAndStartScheduleEvents {
  createAndStartSchedule: (isTop?: boolean) => void
}

export const useCreateAndStartScheduleNewRelease =
  (): CreateAndStartScheduleEvents => {
    const dispatch = useAppDispatch()
    const userId = useAppSelector(getUserId)
    const accessToken = useSelector(getSpotifyToken)
    const { createNewSpotifyCampaign } = useCreateNewCampaign()
    const { data, refetch } = useGetNewReleaseQuery()
    const trackId = data?.trackSpotifyId

    const createAndStartSchedule = useCallback(
      async (isTop?: boolean) => {
        if (trackId && accessToken) {
          void dispatch(getSpotifyTrack({ url: trackId, token: accessToken }))
            .unwrap()
            .then((res) => {
              createNewSpotifyCampaign({
                ...res,
                withApprovalStep: false,
              })

              isTop &&
                newReleasedPromoteEvent({
                  userId,
                  trackId,
                })
              refetch()
            })
            .catch(() => {})
        }
      },
      [
        accessToken,
        trackId,
        dispatch,
        createNewSpotifyCampaign,
        userId,
        refetch,
      ],
    )

    return { createAndStartSchedule }
  }
