import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import TimeAgo from 'react-timeago'

import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'
import { useCreateNewCampaign } from 'src/Containers/SearchNewCampaignByPlatform/SelectedNewCampaign/useCreateNewCampaign'
import LogoSmall from 'src/Assets/Svg/logoSmall.svg?react'
import { SmartImage } from 'src/Components/SmartImage'

import {
  StyledTopBarNewRelease,
  ImgWrapper,
  ReleaseInfo,
  DateWrapper,
  ReleaseInfoTitle,
  ReleaseInfoText,
  ImageInfo,
  Name,
  ImageWrapper,
  InnerImageInfo,
  ButtonWrapper,
  StyledPromoteButton,
} from './styles'

interface TopBarNewReleaseProps {
  handleCreateAndStartSchedule: void
}

export const TopBarNewRelease: FC<TopBarNewReleaseProps> = ({
  handleCreateAndStartSchedule,
}) => {
  const { t } = useTranslation()
  const { data } = useGetNewReleaseQuery()

  const { loading } = useCreateNewCampaign()

  if (!data?.id) {
    return null
  }

  return (
    <StyledTopBarNewRelease>
      <ImgWrapper>
        <LogoSmall width='11.6px' />
      </ImgWrapper>

      <ReleaseInfo>
        <ReleaseInfoTitle>
          {t('campaignsPage.congratsOnYour')}&nbsp;
          {t('campaignsPage.newRelease')}
        </ReleaseInfoTitle>
        <ReleaseInfoText>
          {t('campaignsPage.letsPushYourTrack')}
        </ReleaseInfoText>
        <ImageInfo>
          <InnerImageInfo>
            <ImageWrapper>
              <SmartImage
                alt='trackCoverUrl'
                width='24'
                src={data?.trackImageUrl}
                fallbackSrc={data?.trackImageUrl}
              />
            </ImageWrapper>
            <Name>{data?.trackName}</Name>
          </InnerImageInfo>
          <ButtonWrapper>
            <StyledPromoteButton
              type='green'
              loading={loading}
              onClick={handleCreateAndStartSchedule}
            >
              {t('campaignsPage.promote')}
            </StyledPromoteButton>
          </ButtonWrapper>
        </ImageInfo>
      </ReleaseInfo>
      <DateWrapper>
        <TimeAgo date={data.releaseDate} />
      </DateWrapper>
    </StyledTopBarNewRelease>
  )
}
